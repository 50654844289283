<template>
  <div class="relative">
    <div class="vx-navbar-wrapper navbar-full p-0">
      <vs-navbar
        class="navbar-custom navbar-skelton py-5 h-full"
        :class="navbarClasses"
        :style="navbarStyle"
        :color="navbarColor"
      >
        <div class="container">
          <div class="flex flex-wrap items-center justify-between ">
            <div class="flex">
              <div
                class="cursor-pointer flex flex-wrap items-center mr-8"
              >
                <img
                  style="max-height:45px"
                  :src="logo"
                />
              </div>
              <div class="flex flex-col justify-center"></div>
            </div>
          </div>
        </div>
      </vs-navbar>
    </div>
  </div>
</template>

<script>
export default {
  name: "the-navbar-horizontal",
  data() {
    return {
      logo:"@/assets/images/logo/flipmoney.png",
      serverUrl: process.env.VUE_APP_API_URL
    };
  },
  props: {
    logo: { type: String },
    navbarType: {
      type: String,
      required: true
    },
    merchant: { type: Object }
  },
  components: {},
  methods: {
    formattedUrl(url) {
      if (!url) return "";
      return url.includes("http") ? url : `https://${url}`;
    }
  },
  mounted() {},
  computed: {
    navbarColor() {
      let color = "#fff";
      if (this.navbarType === "sticky") color = "#f7f7f7";
      else if (this.navbarType === "static") {
        if (this.scrollY < 50) {
          color = "#f7f7f7";
        }
      }

      this.isThemedark === "dark"
        ? color === "#fff"
          ? (color = "#10163a")
          : (color = "#262c49")
        : null;

      return color;
    },
    isThemedark() {
      return this.$store.state.theme;
    },
    navbarStyle() {
      return this.navbarType === "static"
        ? { transition: "all .25s ease-in-out" }
        : {};
    },
    navbarClasses() {
      return this.scrollY > 5 && this.navbarType === "static"
        ? null
        : "d-theme-dark-light-bg ";
    },
    scrollY() {
      return this.$store.state.scrollY;
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    }
  }
};
</script>
